import React from 'react';
import './App.css';

import JSON5 from 'json5';

import { GTM_EVENTS, OPEN_FORM_PARAM } from 'appConstants';

import { fireGtmEventFunc } from 'gtm';
import { getLpData, cachedLpState, cacheDefaultLp, getFormData, cachedFormState,
         cacheDefaultForm, calculateFormState } from 'gistData';

import compose from 'recompose/compose';
import withWidth from '@material-ui/core/withWidth';
import { withStyles } from '@material-ui/core/styles';

import { getURLParam, renderHTML, interpolateParams } from 'utils';
// import getLocalCookie, { resetLocalCookie, setLocalCookieValue } from 'localCookie';
import styles from 'styles';

import CircularProgress from '@material-ui/core/CircularProgress';

import formTemplates, { renderForm } from 'formTemplates';
import lpTemplates, { renderTemplate } from 'lpTemplates';

import Navigation from 'components/Navigation';
import SurveyForm from 'components/SurveyForm';
import Footer from 'components/Footer';

class App extends React.PureComponent {
  fireGtmEvent = fireGtmEventFunc.bind(this);
  getLpData = getLpData.bind(this);
  cachedLpState = cachedLpState.bind(this);
  cacheDefaultLp = cacheDefaultLp.bind(this);
  getFormData = getFormData.bind(this);
  renderForm = renderForm.bind(this);
  cachedFormState = cachedFormState.bind(this);
  cacheDefaultForm = cacheDefaultForm.bind(this);
  calculateFormState = calculateFormState.bind(this);
  renderHTML = renderHTML.bind(this);
  interpolateParams = interpolateParams.bind(this);

  constructor(props) {
    super(props);

    const debug = this.cleanStateOnDebug();
    // const { uuid, firstSeen, formCompleted, urlParams } = getLocalCookie();
    const { lpData, lpVariants, lpVariables, lpRevision } = this.cachedLpState(true) || {};
    const { formData, formVariant, formRevision, formSteps, atLastStep, currentRequiredFields, disableNextStep } = this.cachedFormState(true) || {};
    const mobile = props.width === 'xs' || props.width === 's';

    let formFieldsState;
    try {
      formFieldsState = ( localStorage.getItem('formFieldsState') && JSON5.parse( localStorage.getItem('formFieldsState') ) ) || {};
    } catch(err) {
      formFieldsState = {}
    };

    // const formSteps = this.currentForm().length;
    // const currentRequiredFields = this.getRequiredFields(0);

    const openFormOnLoad = !!getURLParam(OPEN_FORM_PARAM);

    this.state = {
      // Initialize
      debug,
      mobile,
      lpIndex: 0,
      formOpen: openFormOnLoad,
      contactFormOpen: false,
      showFormCompletedSuccess: false,
      showContactFormCompletedSuccess: false,
      currentFormStep: 0,
      formFields: formFieldsState,
      // atLastStep: formSteps === 1,
      // disableNextStep: !this.requiredFieldsFilled(formFieldsState, currentRequiredFields),
      // formSteps,
      // currentRequiredFields,

      // State from cookie
      // uuid,
      // firstSeen,
      // urlParams,
      // formCompleted,

      // State from localStorage
      lpData,
      lpVariants,
      lpVariables,
      lpRevision,
      formData,
      formVariant,
      formRevision,
      formSteps,
      atLastStep,
      currentRequiredFields,
      disableNextStep
    };

    this.fireGtmEvent(GTM_EVENTS.stateInitialized, this.state);
  };

  cleanStateOnDebug = () => {
    if(getURLParam('dbg') === '1') {
      if(getURLParam('keepLocalStorage') !== '1') localStorage.clear();
      // if(getURLParam('keepCookie') !== '1') resetLocalCookie();
      return true
    } else {
      return false;
    }
  };

  setFirstPage = () => {
    const { lpData } = this.state;
    const path = window.location.pathname.substr(1);

    const renderLP = lpData.find(lp => lp.slug === path) || lpData[0];
    this.setState({ lpIndex: lpData.indexOf(renderLP) });
  };

  componentDidMount() {
    if(!this.state.lpData) {
      this.getLpData();
    } else {
      this.setFirstPage();
    };
  };

  componentDidUpdate(prevProps, prevState) {
    const { width } = this.props;
    const { lpData, formData } = this.state;

    if(!!lpData && !prevState.lpData) {
      this.setFirstPage();
    } else if (!!lpData && !formData) {
      this.getFormData();
    } else if(prevProps.width !== width) {
      const mobile = width === 'xs' || width === 's';
      if (mobile !== this.state.mobile) {
        this.setState({ mobile }, () => this.fireGtmEvent(GTM_EVENTS.resizedWindow, this.state));
      }
    }
  };

  getRequiredFields = (currentFormStep, form=this.state.formData) => {
    const currentFields = form.fields[currentFormStep];
    return currentFields.filter(field => field.required).map(field => field.attribute);
  };

  requiredFieldsFilled = (formFields = this.state.formFields, currentRequiredFields = this.state.currentRequiredFields) => {
    return currentRequiredFields.filter(f => !formFields[f]).length === 0;
  };

  openForm = (lpSlideIndex, lpSlideType) => {
    this.setState({ formOpen: true }, () => this.fireGtmEvent(GTM_EVENTS.openedForm, { lpSlideIndex, lpSlideType, ...this.state }));
  };

  closeForm = () => {
    this.setState({ formOpen: false }, () => this.fireGtmEvent(GTM_EVENTS.closedForm, this.state));
  };

  onFormSubmit = (httpResponse) => {
    // setLocalCookieValue('fc', true);
    this.setState({
      formCompleted: true,
      showFormCompletedSuccess: true,
      formOpen: false,
    }, () => {
      if(httpResponse.status === 200) {
        this.fireGtmEvent(GTM_EVENTS.postFormSuccess, this.state);
      } else {
        this.fireGtmEvent(GTM_EVENTS.postFormError, { ...this.state, errorType: 'httpError', response: {
          status: httpResponse.status,
          headers: httpResponse.headers,
        } });
      }
    });
  };

  openContactForm = (lpSlideIndex, lpSlideType) => {
    this.setState({ contactFormOpen: true }, () => this.fireGtmEvent(GTM_EVENTS.openedContactForm, { lpSlideIndex, lpSlideType, ...this.state }));
  };

  closeContactForm = () => {
    this.setState({ contactFormOpen: false }, () => this.fireGtmEvent(GTM_EVENTS.closedContactForm, this.state));
  };

  closeFormCompletedSuccess = () => {
    this.setState({ showFormCompletedSuccess: false }, () => this.fireGtmEvent(GTM_EVENTS.closedSuccessMessage, this.state));
  };

  onContactFormSubmit = (httpResponse) => {
    this.setState({
      showContactFormCompletedSuccess: true,
      formOpen: false,
      contactFormOpen: false,
    }, () => {
      if(httpResponse.status === 200) {
        this.fireGtmEvent(GTM_EVENTS.postContactFormSuccess, this.state);
      } else {
        this.fireGtmEvent(GTM_EVENTS.postFormError, { ...this.state, errorType: 'httpError', response: {
          status: httpResponse.status,
          headers: httpResponse.headers,
        } });
      }
    });
  };

  closeContactFormCompletedSuccess = () => {
    this.setState({ showContactFormCompletedSuccess: false }, () => this.fireGtmEvent(GTM_EVENTS.closedContactFormSuccessMessage, this.state));
  };

  onFaqQuestionOpen = (questionIndex, questionContent, lpSlideIndex) => {
    this.fireGtmEvent(GTM_EVENTS.openedFaqQuestion, { questionIndex, questionContent, lpSlideIndex });
  };

  onTabChange = (tabIndex, tabContent, lpSlideIndex) => {
    this.fireGtmEvent(GTM_EVENTS.openedTab, { tabIndex, tabContent, lpSlideIndex });
  };

  onTagHover = (taggedTextItem, tag, lpSlideIndex) => {
    this.fireGtmEvent(GTM_EVENTS.hoveredTag, { taggedTextItem, tag, lpSlideIndex });
  }

  onOpenDialog = (buttonIndex, dialogContent, lpSlideIndex) => {
    this.fireGtmEvent(GTM_EVENTS.openedDialog, { buttonIndex, dialogContent, lpSlideIndex });
  };

  onNavigationClick = (lpIndex, newLpTitle) => {
    // const element = document.querySelector(`.navigationAnchor${labelIndex + 1}`);
    // if(element) {
    //   let scrollTo = element.offsetTop + 50;

    //   // If the first error is close to the top,
    //   // we scroll to the top
    //   if(scrollTo < 200) scrollTo = 0;
    //   Scroll.animateScroll.scrollTo(scrollTo, {
    //     duration: 1500,
    //     delay: 100,
    //     smooth: "easeInOutQuint",
    //   });
    // }
    if(lpIndex !== this.state.lpIndex) {
      const { lpData } = this.state;
      const lp = lpData[lpIndex];
      window.history.pushState(null, lp.title, lp.slug || '/');
      this.setState({ lpIndex }, () => {
        window.scrollTo(0,0);
        this.fireGtmEvent(GTM_EVENTS.navigatedToPage, {...this.state, newLpTitle});
      });
    };
  };

  onChange = (attribute, value, gtmEvent = GTM_EVENTS.startedChangingFormFieldValue) => {
    const newFormFields = { ...this.state.formFields, ...{[attribute]: value} };
    const wasEmpty = !this.state.formFields[attribute];

    this.setState({
      formFields: newFormFields,
      disableNextStep: !this.requiredFieldsFilled(newFormFields),
    }, () => {
      if(wasEmpty) {
        this.fireGtmEvent(gtmEvent, { fieldAttribute: attribute });
      };
      this.fieldChanged = true;
    });
  };

  onFormFieldFocus = (attribute, gtmEvent = GTM_EVENTS.focusedOnFormField) => {
    if(this.lastFocusedOn !== attribute) {
      this.fireGtmEvent(gtmEvent, { ...this.state, fieldAttribute: attribute, fieldValue: this.state.formFields[attribute], disableNextStep: this.state.disableNextStep });
      this.lastFocusedOn = attribute;
    }
  };

  onFormFieldBlur = (attribute, gtmEvent = GTM_EVENTS.focusedOutOfFormField) => {
    if(this.fieldChanged) {
      this.fireGtmEvent(gtmEvent, { ...this.state, fieldAttribute: attribute, fieldValue: this.state.formFields[attribute], disableNextStep: this.state.disableNextStep });
      this.fieldChanged = false;
    }
  };

  render() {
    const { classes, width } = this.props;
    const { lpData, lpIndex, formFields, formData } = this.state;

    const mobile = width === 'xs' || width === 's';

    if(!lpData) {
      return (
        <div className={classes.loadingContainer}>
          <CircularProgress />
        </div>
      );
    };

    // const menuItems = lpData[lpIndex].sections.filter(s => !!s.navigationLabel);
    const lpTemplate = renderTemplate.bind(this)(lpData[lpIndex]);
    const { cta: formCta, formCompletedCta, successMessage, formTitle } = { ...lpTemplates.default[0].globals, ...(lpData[lpIndex] ? lpData[lpIndex].globals : {}) };

    const { email, name } = formFields;
    const { message, ...filteredFormFields } = formFields;

    // TODO retrieve list from formTemplates
    const contactFormFields = { email, name, message };

    const defaultSuccessMessage = "Thank you for contacting us, someone will come back to you shortly. Have a great day!";

    return (
      <div className={`${classes.container}`}>
        <Navigation
          onNavigationClick={this.onNavigationClick}
          cta={this.state.formCompleted ? formCompletedCta : formCta}
          onCtaClick={this.state.formCompleted ? this.openContactForm : this.openForm}
          lpData={lpData}
          width={width}
          renderHTML={this.renderHTML}
          fireGtmEvent={this.fireGtmEvent} 
        />

        { lpTemplate }

        <Footer
          mobile={mobile}
          renderHTML={this.renderHTML}
          onOpenContactForm={this.openContactForm}
          fireGtmEvent={this.fireGtmEvent} />

        {formData &&
          <SurveyForm
            withFooter={mobile}
            renderHTML={this.renderHTML}
            fireGtmEvent={this.fireGtmEvent}
            mobile={mobile}
            title={formTitle}
            // classes={classes}
            state={this.state}
            stateOverride={{
              formFields: filteredFormFields,
            }}
            open={this.state.formOpen}
            onClose={this.closeForm}
            onFormSubmit={this.onFormSubmit}
            showSuccessDialog={this.state.showFormCompletedSuccess}
            onSuccessClose={this.closeFormCompletedSuccess}
            postBeforeUnload={false}
            cta={formCta}
            successMessage={successMessage || defaultSuccessMessage}
            setState={diff => this.setState(diff)}
            formTemplate={formData}
            renderForm={this.renderForm}
            requiredFieldsFilled={this.requiredFieldsFilled}
          />
        }

        {formData &&
          <SurveyForm
            mobile={mobile}
            title={"Contact us"}
            // classes={classes}
            state={this.state}
            stateOverride={{
              formCompleted: false,
              formFields: contactFormFields,
              formSteps: 0,
              currentFormStep: 0,
              atLastStep: true,
            }}
            open={this.state.contactFormOpen}
            onClose={this.closeContactForm}
            onFormSubmit={this.onContactFormSubmit}
            showSuccessDialog={this.state.showContactFormCompletedSuccess}
            onSuccessClose={this.closeContactFormCompletedSuccess}
            cta={"Submit"}
            successMessage={defaultSuccessMessage}
            fireGtmEvent={this.fireGtmEvent}
            setState={diff => this.setState(diff)}
            formTemplate={formTemplates.contactForm}
            renderForm={this.renderForm}
            requiredFieldsFilled={() => this.requiredFieldsFilled(contactFormFields, this.getRequiredFields(0, formTemplates.contactForm))}
            onChange={(attribute, value) => this.onChange(attribute, value, GTM_EVENTS.changedContactFormFieldValue)}
            onFocus={(attribute, value) => this.onFormFieldFocus(attribute, value, GTM_EVENTS.focusedOnContactFormFieldValue)}
            onBlur={(attribute, value) => this.onFormFieldBlur(attribute, value, GTM_EVENTS.focusedOutOfContactFormFieldValue)}
          />
        }
      </div>
    );
  }
}

export default compose(withStyles(styles), withWidth())(App);