import GitHub from 'github-api';
import JSON5 from 'json5';

import lpTemplates from 'lpTemplates';
import formTemplates from 'formTemplates';
import { getURLParam } from 'utils';
import { GITHUB_LPS_GIST_ID, GITHUB_LP_VARIABLES_FILE_NAME, GITHUB_FORMS_GIST_ID, GTM_EVENTS,
         LP_VARIANT_URL_PARAM, LP_VARIABLES_URL_PARAM, FORM_VARIANT_URL_PARAM, READONLY_GIST_OAUTH_KEY } from 'appConstants';

export function getLpData() {
  this.fireGtmEvent(GTM_EVENTS.displayLoading, {});
  try {
    const lpVariantsParam = getURLParam(LP_VARIANT_URL_PARAM);
    const lpVariablesParam = getURLParam(LP_VARIABLES_URL_PARAM);
    if (lpVariantsParam || lpVariablesParam) {
      const gh = new GitHub({
        username: 'bt-gists',
        token: READONLY_GIST_OAUTH_KEY
      });
      gh.getGist(GITHUB_LPS_GIST_ID).read().then(gist => {
        if(gist.status === 200 && gist.data.files) {
          let lpVariables = {
            ...lpTemplates.defaultVariables,
          };

          if(lpVariablesParam) {
            const lpVariablesFile = gist.data.files[GITHUB_LP_VARIABLES_FILE_NAME].content;
            if(lpVariablesFile) {
              const lpVariablesSet = JSON5.parse(lpVariablesFile)[lpVariablesParam];
              if(lpVariablesSet) {
                lpVariables = {
                  ...lpVariables,
                  ...lpVariablesSet,
                }
              }
            }
          };

          // localStorage.setItem('lpVariables', JSON.stringify(lpVariables));

          let lpData = [];
          let lpVariants = [];

          if(lpVariantsParam) {
            lpVariants = lpVariantsParam.split(',');
            lpVariants.forEach(lpVariant => {
              const lpKey = lpVariant + '.json';
              if(gist.data.files[lpKey] && gist.data.files[lpKey].content) {
                try {
                  lpData.push(JSON5.parse(gist.data.files[lpKey].content));
                } catch(err) {
                  console.log('Error parsing JSON:', lpKey, err)
                }
              };
            });
            // localStorage.setItem('lpVariants', JSON.stringify(lpVariants));
          };

          if (lpData.length === 0) {
            this.cacheDefaultLp(!lpVariantsParam ? { errorType: 'missingParam', errorParam: LP_VARIANT_URL_PARAM } : undefined);
          } else {
            // localStorage.setItem('lpData', JSON.stringify(lpData));
            const lpRevision = gist.data.history && gist.data.history[0] ? gist.data.history[0].version : null;
            if(lpRevision) {
              // localStorage.setItem('lpRevision', gist.data.history[0].version);
            };
            const lpState = {  lpData, lpVariants, lpRevision, lpVariables };
            this.setState(lpState, () => this.fireGtmEvent(GTM_EVENTS.fetchLpTemplateSuccess, this.state));
          };
          return;
        }
        this.cacheDefaultLp( { errorType: 'httpError', response: { status: gist.status } } );
      });
    } else {
      this.cacheDefaultLp( { errorType: 'missingParam', errorParam: LP_VARIANT_URL_PARAM } );
    }
  } catch(err) {
    const lpLoaded = this.state.lpData;
    if(!lpLoaded) {
      this.cacheDefaultLp({ errorType: 'exception', fetchedTemplate: lpLoaded, error: err });
    } else {
      this.fireGtmEvent(GTM_EVENTS.fetchLpTemplateError, { errorType: 'exception', fetchedTemplate: lpLoaded, error: err, ...this.state });
    }
  }
};

export function getFormData() {
	if(!this.state.formData) {
    try {
      const formVariant = getURLParam(FORM_VARIANT_URL_PARAM);
      if (formVariant) {
        const formKey = formVariant + '.json';
        const gh = new GitHub();
        gh.getGist(GITHUB_FORMS_GIST_ID).read().then(gist => {
          if(gist.status === 200 && gist.data.files && gist.data.files[formKey]) {
            if(gist.data.files[formKey] && gist.data.files[formKey].content) {
              try {
                // localStorage.setItem('formData', JSON.stringify(JSON5.parse(gist.data.files[formKey].content)));
                // localStorage.setItem('formVariant', formVariant);
                if(gist.data.history && gist.data.history[0] && gist.data.history[0].version) {
                  // localStorage.setItem('formRevision', gist.data.history[0].version);
                };
              } catch(err) {
                console.log('Error parsing JSON:', formKey, err)
              }
            };

            const formState = this.cachedFormState();
            this.setState({ ...formState }, () => this.fireGtmEvent(GTM_EVENTS.fetchFormTemplateSuccess, formState));
            return
          }
          this.cacheDefaultForm({ errorType: 'httpError', response: { status: gist.status } });
        })
      } else {
        this.cacheDefaultForm({ errorType: 'missingParam', errorParam: FORM_VARIANT_URL_PARAM });
      }
    } catch(err) {
      const formLoaded = this.state.formData;
      if(!formLoaded) {
        this.cacheDefaultForm({ errorType: 'exception', fetchedTemplate: formLoaded, error: err });
      } else {
        this.fireGtmEvent(GTM_EVENTS.fetchFormTemplateError, { errorType: 'exception', fetchedTemplate: formLoaded, error: err, ...this.state });
      }
    }
  };
};

export function cachedLpState(firstLoad = false) {
  try {
    if(!!localStorage.getItem('lpData')) {
      return {
        lpData: JSON5.parse( localStorage.getItem('lpData') ),
        lpVariants: JSON5.parse( localStorage.getItem('lpVariants') ),
        lpVariables: JSON5.parse( localStorage.getItem('lpVariables') ),
        lpRevision: localStorage.getItem('lpRevision'),
      }
    } else {
      const lpVariants = getURLParam(LP_VARIANT_URL_PARAM) ? getURLParam(LP_VARIANT_URL_PARAM).split(',') : ['default'];
      const lpVariables = getURLParam(LP_VARIABLES_URL_PARAM);
      return { lpVariants, ...lpVariables ? lpVariables : {} };
    }
  } catch(err) {
    if(firstLoad) {
      return undefined;
    } else {
      this.cacheDefaultLp();
    }
  }
};

export function cachedFormState(firstLoad = false) {
  try {
    if(!!localStorage.getItem('formData')) {
    	const formData = JSON5.parse( localStorage.getItem('formData') );
      return {
        formData,
        formVariant: localStorage.getItem('formVariant'),
        formRevision: localStorage.getItem('formRevision'),
        ...this.calculateFormState(formData),
      }
    }
  } catch(err) {
    if(firstLoad) {
      return undefined;
    } else {
      this.cacheDefaultForm();
    }
  }
};

export function calculateFormState(formTemplate, currentStep=0, state=this.state) {
	const formSteps = formTemplate.fields.length;
	const atLastStep = formSteps === currentStep + 1;
	const currentRequiredFields = formTemplate.fields[currentStep].filter(f => f.required).map(f => f.attribute);
	const disableNextStep = currentRequiredFields.filter(f => !state.formFields[f]).length > 0;

	return { formSteps, atLastStep, currentRequiredFields, disableNextStep };
}

export function cacheDefaultLp(withErrorEvent) {
  const lpRevision = (new Date()).getTime().toString();

  // localStorage.setItem('lpData', JSON.stringify( lpTemplates.default ));
  // localStorage.setItem('lpVariants', JSON.stringify( ['default'] ) );
  // localStorage.setItem('lpRevision', lpRevision);

  let lpVariables = localStorage.getItem('lpVariables') && JSON5.parse( localStorage.getItem('lpVariables') );

  if(!lpVariables) {
    lpVariables = { ...lpTemplates.defaultVariables };
    // localStorage.setItem( 'lpVariables', JSON.stringify(lpVariables) );
  };

  const lpState = {
    lpData: lpTemplates.default,
    lpVariants: ['lp1Default'], // , 'tp1Default', 'mp1Default', 'cp1Default'],
    lpRevision,
    lpVariables,
  };

  this.setState(lpState, () => {
    if(!!withErrorEvent) {
      this.fireGtmEvent(GTM_EVENTS.fetchLpTemplateError, { ...withErrorEvent, ...this.state })
    }
  });
};

export function cacheDefaultForm(withErrorEvent) {
  const formRevision = (new Date()).getTime().toString();

  // localStorage.setItem('formData', JSON.stringify( formTemplates.default ));
  // localStorage.setItem('formVariant', 'default');
  // localStorage.setItem('formRevision', formRevision);

  const formState = {
    formData: formTemplates.default,
    formVariant: 'ff1default',
    formRevision: formRevision,
    ...this.calculateFormState(formTemplates.default),
  };

  this.setState(formState, () => {
    if(!!withErrorEvent) {
      this.fireGtmEvent(GTM_EVENTS.fetchFormTemplateError, { ...withErrorEvent, ...this.state })
    }
  });

  return formState;
};